import React, { Fragment } from "react"
import { Highlight, Snippet } from "react-instantsearch-dom"
import { Link } from "gatsby"
import { Calendar } from "styled-icons/octicons/Calendar"
import { Tags } from "styled-icons/fa-solid/Tags"

import styles from '../../components/Feed/Feed.module.scss';
import moment from 'moment';

export const PostHit = clickHandler => ({ hit }) => (
  <div className={styles['feed']}>
    <div className={styles['feed__item']} key={hit.node.frontmatter.slug}>
      <div className={styles['feed__item-meta']}>
        <time className={styles['feed__item-meta-time']} dateTime={moment(hit.node.frontmatter.date).format('MMMM D, YYYY')}>
          {moment(hit.node.frontmatter.date).format('MMMM D, YYYY')}
        </time>
      </div>
      <h2 className={styles['feed__item-title']}>
        <Link className={styles['feed__item-title-link']} to={hit.node.frontmatter.slug}>{hit.node.frontmatter.title}</Link>
      </h2>
      {/* <p className={styles['feed__item-description']}>{hit.node.excerpt}</p> */}
      <Link className={styles['feed__item-readmore']} to={hit.node.frontmatter.slug}>Read</Link>
    </div>
  </div>
)