import React, { useState, useEffect, useMemo, createRef } from "react"
import {
  InstantSearch,
  Index,
  Hits,
  connectStateResults,
  PoweredBy
} from "react-instantsearch-dom"
import algoliasearch from "algoliasearch/lite"


import Input from "./input"
import * as hitComps from "./hitComps"

import styles from './Search.module.scss';

const Results = connectStateResults(
  ({ searchState: state, searchResults: res, children }) =>
    res && res.nbHits > 0 ? children : `No results for '${state.query}'`
)

const Stats = connectStateResults(
  ({ searchResults: res }) =>
    res && res.nbHits > 0 && `${res.nbHits} result${res.nbHits > 1 ? `s` : ``}`
)

const useClickOutside = (ref, handler, events) => {
  if (!events) events = [`mousedown`, `touchstart`]
  const detectClickOutside = event =>
    !ref.current.contains(event.target) && handler()
  useEffect(() => {
    for (const event of events)
      document.addEventListener(event, detectClickOutside)
    return () => {
      for (const event of events)
        document.removeEventListener(event, detectClickOutside)
    }
  })
}

export default function Search({ indices, collapse = true, hitsAsGrid }) {
  const ref = createRef()
  const [query, setQuery] = useState(``)
  const [focus, setFocus] = useState(false)
  // useMemo prevents the searchClient from being recreated on every render.
  // Avoids unnecessary XHR requests (see https://tinyurl.com/yyj93r2s).
  const searchClient = useMemo(
    () =>
      algoliasearch(
        process.env.GATSBY_ALGOLIA_APP_ID,
        process.env.GATSBY_ALGOLIA_SEARCH_KEY
      ),
    []
  )
  // useClickOutside(ref, () => setFocus(false))

  if (query !== undefined && query.length > 0 && focus) {
    return (
      <InstantSearch
        searchClient={searchClient}
        indexName={indices[0].name}
        onSearchStateChange={({ query }) => setQuery(query)}
        className={styles['search_box']}
      >
        <Input onFocus={() => setFocus(true)} {...{ collapse, focus }} />
        <div className={styles['search_list']}>
          {indices.map(({ name, title, hitComp }) => (
            <Index key={name} indexName={name}>
              <PoweredBy
                translations={{
                  searchBy: 'Search by  ',
                }}
                className={styles['search_box1']}
              />
              <header>
                <Stats />
              </header>
              <Results>
                <Hits hitComponent={hitComps["PostHit"](() => setFocus(false))} />
              </Results>

            </Index>
          ))}
        </div>
        <div className={styles['search_bottom_line']}></div>
      </InstantSearch>
    )
  } else {
    return (
      <InstantSearch
        searchClient={searchClient}
        indexName={indices[0].name}
        onSearchStateChange={({ query }) => setQuery(query)}
        className={styles['search_box']}
      >
        <Input onFocus={() => setFocus(true)} {...{ collapse, focus }} />
      </InstantSearch>
    )
  }
}