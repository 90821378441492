import React from "react"
import { connectSearchBox } from "react-instantsearch-dom"

import { SearchIcon, Form, Input } from "./styles"
import styles from './Search.module.scss';

export default connectSearchBox(({ refine, ...rest }) => (
  <Form onSubmit={e => { e.preventDefault(); }}>
    <Input
      type="search"
      placeholder="Search"
      onChange={e => refine(e.target.value)}
      {...rest}
      className={styles['search_box']}
    />
  </Form>
))